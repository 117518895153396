@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  #__next {
    height: 100%;
    width: 100%;
  }

  @font-face {
    font-family: 'Volkhov';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 300 600;
    font-display: swap;
    font-style: normal;
  }
}
